<!-- CP 등록/수정 -->
<template>
  <v-container
          id="register"
          fluid
          tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              CP {{resultPartner}}
            </h1>
            <div class="subtitle-1 font-weight-regular">
              CP를 {{resultPartner}}하세요.
            </div>
          </template>
          <v-card-text>
            <h2 style="padding: 20px 10px;">
              CP 정보 {{resultPartner}}
            </h2>
            <v-form
                    v-model="vModel.valid"
                    ref="form">
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  CP명
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="CP명"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.name"
                          :rules="config.mediaNameRules"
                          :disabled="$route.name === 'partnersEdit'"
                          required />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  정산율
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="정산율"
                          type="number"
                          color="secondary"
                          v-model="vModel.data.commission"
                          :rules="config.commissionRules"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  이름
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="이름"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.user.name"
                          :rules="config.nameRules"
                          :disabled="$route.name === 'partnersEdit'"
                          required />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  계정 이메일
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="계정 이메일"
                          type="email"
                          color="secondary"
                          ref="email"
                          v-model="vModel.data.user.email"
                          :rules="config.emailRules"
                          @blur="emailCheck"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  비밀번호
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="비밀번호"
                          type="password"
                          color="secondary"
                          v-model="vModel.data.user.password" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  비밀번호 확인
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="비밀번호 확인"
                          type="password"
                          color="secondary"
                          v-model="vModel.data.user.passwordConfirmation" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  대표자
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="대표자"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.company.ceo" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  주소
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="주소"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.company.address" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  사업자등록번호
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="사업자 등록번호"
                          type="text"
                          v-mask="businessNoMask"
                          color="secondary"
                          v-model="vModel.data.company.businessNo" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  전화번호
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="전화번호"
                          type="text"
                          v-mask="managerPhoneMask"
                          color="secondary"
                          v-model="vModel.data.company.managerPhone" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  담당자명
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="담당자명"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.company.managerName" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  담당자 이메일
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="담당자 이메일"
                          type="email"
                          color="secondary"
                          v-model="vModel.data.company.managerEmail" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  은행명
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="은행명"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.company.bankName" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  계좌번호
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="계좌번호"
                          type="number"
                          color="secondary"
                          v-model="vModel.data.company.bankAccount" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  세금계산서<br>발행 이메일
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="세금계산서 발행 이메일"
                          type="email"
                          color="secondary"
                          v-model="vModel.data.company.taxEmail" />
                </v-col>
                <v-col cols="1" class="text-right grey--text">
                  사이트 주소
                </v-col>
                <v-col cols="5">
                  <v-text-field
                          placeholder="사이트 주소"
                          type="text"
                          color="secondary"
                          v-model="vModel.data.site_url" />
                </v-col>
              </v-row>

              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col
                  cols="1"
                  class="text-right grey--text">
                  제휴 상태
                </v-col>
                <v-col cols="5">
                  <v-select :items="config.contractStatusOptions"
                            v-model="vModel.data.contract_status_id"
                            placeholder="제휴 상태"
                            color="secondary" />
                </v-col>
              </v-row>

              <!--매체 수정시에만-->
              <v-row class="pl-5 pr-5"
                     v-if="response.mediaList">
                <v-col
                        cols="1"
                        class="text-right grey--text">
                  매체 구독 현황
                </v-col>
                <v-col cols="5">
                  <v-checkbox label="구독 자동 승인" v-model="vModel.linkBool" class="ma-0 pt-0"></v-checkbox>
                  <div style="max-height:350px;overflow-y: auto;border: solid 1px #ddd;border-width: 1px 0;">
                    <v-data-table
                            style="width:100%"
                            :headers="config.headers"
                            :items="response.mediaList"
                            :items-per-page="Number(response.mediaList.length)"
                            hide-default-footer>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-center">{{item.kor_name}}</td>
                          <td class="text-center">{{item.name}}</td>
                          <!--구독중/ 구독신청한 매체가 있을때-->
                          <td class="text-center" v-if="response.partners.linkPartner.length > 0">
                            <!--구독중/ 신청 안한 매체-->
                            <template v-if="resultLinkPartners(item)">
                              -
                            </template>
                            <!--구독중/ 신청한 매체 -->
                            <template v-else>
                              <!--구독신청한 매체-->
                              <template v-if="resultLinkPartners2(item) === 0">
                                <v-btn @click="putLinkPartners(item)" small rounded color="success">구독 승인</v-btn>
                              </template>
                              <!--구독중 매체-->
                              <span v-else>구독중</span>
                            </template>
                          </td>
                          <td v-else class="text-center">-</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else
                     class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  매체 구독 설정
                </v-col>
                <v-col>
                  <v-checkbox label="구독 자동 승인" v-model="vModel.linkBool"></v-checkbox>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-5">
                <template v-if="this.$route.name === 'partnersRegister'">
                  <v-btn
                          :disabled="!vModel.valid"
                          class="mt-5"
                          color="success"
                          @click="submitRegister">
                    등록
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                          class="mt-5"
                          color="default"
                          @click="beforePage">
                    취소
                  </v-btn>
                  <v-btn
                        :disabled="!vModel.valid"
                        class="mt-5"
                        color="success"
                        @click="userEdit">
                    수정
                  </v-btn>
                </template>
              </v-row>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        media: null,
        mediaList: null,
        partners: null,
        putLink: null,
        register: null,
      },
      vModel: {
        valid: true,
        linkBool: true,
        data: {
          mode: 'edit',
          name: '',
          commission: '',
          site_url: '',
          user: {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
          },
          company: {
            ceo: '',
            address: '',
            businessNo: '',
            taxEmail: '',
            managerName: '',
            managerPhone: '',
            managerEmail: '',
            bankName: '',
            bankAccount: '',
          },
          contract_status_id: 1
        },
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null
      },
      config: {
        linkPartners: null,
        headers: [
          { text: '매체명', value: 'kor_name', align: 'center' },
          { text: '도메인', value: 'name', align: 'center' },
          { text: '상태', value: 'status', align: 'center' },
        ],
        nameRules: [ v => !!v || '이름을 입력하세요.' ],
        emailRules: [
          v => !!v || '이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.'
        ],
        passwordRules: [ v => !!v || '비밀번호를 입력하세요.',
          v => v.length > 5 || '비밀번호를 6자 이상 입력하세요.' ],
        passwordConfirmationRules: [
          v => !!v || '비밀번호를 재입력하세요.',
          v => this.vModel.data.user.password === v || '입력한 비밀번호와 같은 값을 입력하세요.' ],
        mediaNameRules: [ v => !!v || 'CP명을 입력하세요.' ],
        managerNameRules: [ v => !!v || '담당자명를 입력하세요.' ],
        managerPhoneRules: [ v => !!v || '전화번호를 입력하세요.',
          v => v.length > 9 || '전화번호를 10자 이상 입력하세요.' ],
        managerEmailRules: [ v => !!v || '담당자 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        ceoRules: [ v => !!v || '대표자명을 입력하세요.' ],
        addressRules: [ v => !!v || '주소를 입력하세요.' ],
        businessNoRules: [ v => !!v || '사업자 등록 번호를 입력하세요.' ],
        taxEmailRules: [ v => !!v || '세금계산서를 발행 받을 이메일을 입력하세요.',
          v => /.+@.+\..+/.test(v) || '이메일 형식에 맞게 입력하세요.' ],
        bankNameRules: [ v => !!v || '은행명 입력하세요.' ],
        bankAccountRules: [ v => !!v || '계좌번호를 숫자만 입력하세요.',
          v => v.length > 9 || '계좌번호를 10자 이상 입력하세요.' ],
        commissionRules: [ v => !!v || '정산율을 입력하세요.' ],
        contractStatusOptions: [
          // { value: 'all', text: '전체' },
          { value: 1, text: '등록' },
          { value: 2, text: '준비 중' },
          { value: 3, text: '제휴 중' },
          { value: 4, text: '일시 정지' },
          { value: 6, text: '보류' },
          { value: 5, text: '중단' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      let name = this.$route.name
      if (name === 'partnersEdit') {
        this.getPartners()
      }
    },
    /* 구독 신청 확인 */
    resultLinkPartners (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      if (list.length > 0) {
        // 신청한거
        return false
      } else {
        // 신청안한거
        return true
      }
    },
    /* 구독 신청 중일때 해당값 return */
    resultLinkPartners2 (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      return list[0].value
    },
    /* 구독 승인하기 */
    putLinkPartners (val) {
      if (confirm('구독 승인하겠습니까?')) {
        this.xhttp({
          url: '/' + val.key + '/link/' + this.$route.params.partners_id,
          method: 'put',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.putLink = response.data.data
            this.snack('success', '승인 완료되었습니다.')
            this.getPartners()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 매체 리스트 가져오기 */
    mediaList () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'kor_name',
        direction: 'asc',
        contract_status_id: 3
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          for (let i = 0; i < this.response.media.length; i++) {
            this.response.media[i].idx = i
          }
          let list = this.config.linkPartners
          let arr = []
          if (this.config.linkPartners) {
            /* 구독된 cp 먼저 arr에 넣기 */
            for (let i = 0; i < list.length; i++) {
              let result = this.response.media.filter(function ($value) {
                return $value.id === list[i].id
              })
              if (result.length > 0) {
                arr.push(result[0])
              }
            }
            /* 구독되지 않은 cp 를 arr 에 넣기 */
            let mediaList = [ ...this.response.media ]
            for (let i = 0; i < arr.length; i++) {
              mediaList[arr[i].idx] = null
            }
            mediaList = mediaList.filter(function ($value) {
              return $value
            })
            arr = arr.concat(mediaList)
          } else {
            arr = list
          }
          this.response.mediaList = arr
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp 상세정보 가져오기 */
    getPartners () {
      let id = this.$route.params.partners_id
      this.xhttp({
        url: '/partners/' + id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.partners = response.data.data.partner
          this.vModel.data = this.response.partners
          this.vModel.data.mode = 'edit'
          this.vModel.data.user.password = ''
          this.vModel.data.user.passwordConfirmation = ''
          if (!this.vModel.data.company) {
            this.vModel.data.company = {
              ceo: '',
              address: '',
              businessNo: '',
              taxEmail: '',
              managerName: '',
              managerPhone: '',
              managerEmail: '',
              bankName: '',
              bankAccount: '',
            }
          }
          this.vModel.linkBool = this.response.partners.auto === 1
          this.config.linkPartners = null
          if (this.response.partners.linkPartner.length > 0) {
            this.config.linkPartners = this.response.partners.linkPartner.map(function (val) {
              return { id: val.media_id, value: val.partner_approved }
            })
          }
          this.mediaList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp 등록하기 */
    submitRegister () {
      if (this.$refs.form.validate()) {
        if (confirm('등록 하시겠습니까?')) {
          let send = this.vModel.data
          send.auto = this.vModel.linkBool ? 1 : 0
          send.commission = Number(send.commission)
          this.xhttp({
            url: '/partners',
            method: 'post',
            data: send
          }).then((response) => {
            if (response.status === 201) {
              this.response.register = response.data.data
              this.snack('success', 'CP 등록이 완료되었습니다.')
              this.$router.push({ name: 'partnersList' })
            }
          }).catch((error) => {
            if (error.message === 'Request failed with status code 401') {
              this.$router.push({ 'name': 'UserLogin' })
            } else {
              this.snack('warning', '관리자에게 문의 바랍니다.')
            }
          })
        }
      }
    },
    /* 수정 버튼 클릭시 */
    userEdit () {
      if (this.$refs.form.validate()) {
        let password = this.vModel.data.user.password
        if (password) {
          if (password.length > 6) {
            let passwordConfirm = this.vModel.data.user.passwordConfirmation
            if (passwordConfirm) {
              if (password === passwordConfirm) {
                this.putUser()
              } else {
                this.snack('warning', '입력한 비밀번호와 같은 값을 입력하세요.')
              }
            } else {
              this.snack('warning', '비밀번호 확인을 입력하세요.')
            }
          } else {
            this.snack('warning', '비밀번호 6자 이상 입력하세요.')
          }
        } else {
          this.putUser()
        }
      }
    },
    /* cp 수정하기 */
    putUser () {
      if (confirm('수정 하시겠습니까?')) {
        let id = this.$route.params.partners_id
        let send = this.vModel.data
        send.auto = this.vModel.linkBool ? 1 : 0
        send.commission = Number(send.commission)
        this.xhttp({
          url: '/partners/' + id,
          method: 'put',
          data: send
        }).then((response) => {
          if (response.status === 200) {
            this.response.edit = response.data.data
            this.snack('success', 'CP 정보 수정 완료되었습니다.')
            this.beforePage()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 이메일 input 벗어났을때 */
    emailCheck () {
      if (this.$route.name === 'partnersRegister') {
        let v = this.vModel.data.user.email
        // 이메일 형식일때
        if (/.+@.+\..+/.test(v)) {
          this.postEmailValidate()
        }
      }
    },
    /* 이메일 중복 체크 */
    postEmailValidate () {
      let send = {
        class: 'Partner\\PartnerStoreRequest',
        user: {
          email: this.vModel.data.user.email
        }
      }
      this.xhttp({
        url: '/validation',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.validation = response.data
          if (!this.response.validation.success) {
            if (this.response.validation.errors['user.email']) {
              this.snack('warning', '이미 사용중인 이메일 입니다.')
              this.$refs.email.focus()
            }
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
  },
  computed: {
    managerPhoneMask () {
      let numberLength = this.vModel.data.company.managerPhone
      if (numberLength === 9) {
        return '####-####'
      } else if (numberLength === 11) {
        return '##-###-####'
      } else if (numberLength === 12) {
        return '###-###-####'
      } else {
        return '###-####-####'
      }
    },
    businessNoMask () {
      return '###-##-######'
    },
    resultPartner () {
      if (this.$route.name === 'partnersRegister') {
        return '등록'
      } else {
        return '수정'
      }
    }
  }
}
</script>
